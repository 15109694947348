<template>
  <!-- <svg class="icon" :class="'icon-' + props.size" aria-hidden="true" :color="props.color">
    <use :xlink:href="'#icon-' + props.name"></use>
  </svg> -->
  <i 
    class="iconfont" 
    :class="'icon-' + props.size , 'icon-' + props.name" 
    :color="props.color"
  ></i>
</template>

<script setup>
import { ref,defineProps } from 'vue'
const props = defineProps({
  size: {
    type: String,
    default: 'small'
  },
  name: {
    type: String,
    default: 'lock'
  },
  color: {
    type: String,
    default: '#545454'
  }
})

</script>

<style lang="scss" scoped>
.icon {
  &-small1 {
    font-size: 12px;
  }
  &-small {
    font-size: 16px;
  }

  &-middle {
    font-size: 20.8px;
  }

  &-large {
    font-size: 28.8px;
  }
}
</style>
